import React from 'react';
import '../css/MainContent.css';
import '../css/AboutContent.css';

function AboutContent() {
  return (
    <div id="about-content" className="content">
      <div className="about-flex">
        <div className="profile-section">
        <img src="/images/profile.jpg" alt="Profile Photo" className="profile-img" id="profile-image" />
          <p className="photo-caption">管理人、昌平橋にて(Photograph by Minoru Saito, Tokyo Nov.2013)</p>
        </div>
        <div className="career">
          <h2>経歴</h2>
          <table>
            <tr>
              <td><i>1986.Jan.8</i></td>
              <td>誕生</td>
            </tr>
            <tr>
              <td><i>2013.Feb.</i></td>
              <td>辻楽士として東京の路上でビオラ演奏活動</td>
            </tr>
            <tr>
              <td><i>2024.Jan.</i></td>
              <td>AIを活用しプログラミング勉強開始</td>
            </tr>
            <tr>
              <td><i>2024.Jul.</i></td>
              <td>個人プロジェクト<a href="https://abehiroto.com/wmapp">"Obsessed with Watermelon"</a>リリース（Go + Flutter）</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AboutContent;
