import React, { useEffect } from 'react';
import '../css/MainContent.css';
import '../css/ContactContent.css';

function ContactContent() {
  useEffect(() => {
    // モーダル関連の要素を取得
    const modal = document.getElementById('confirmationModal');
    const closeButton = document.querySelector('.close-button');
    const form = document.getElementById('contact-form');
    const confirmButton = document.getElementById('confirmButton');
    const submitButton = document.getElementById('submitButton');

    // フォームの送信イベントを防ぐ
    const handleFormSubmit = (event) => {
      event.preventDefault(); // フォームのデフォルト送信を防ぐ
    };

    // 確認ボタンがクリックされたときの処理
    const handleConfirmClick = (event) => {
      event.preventDefault(); // デフォルト動作を防ぐ
      const formData = new FormData(form);

      // 入力チェック（名前、メールアドレス、メッセージの必須チェック）
      if (!formData.get('name').trim() || !formData.get('email').trim() || !formData.get('message').trim()) {
        alert("*付きの欄は必須です/Please fill in all fields marked with *");
        return; // フォームの必須フィールドが埋まっていない場合、処理を中断
      }

      // フォームデータから入力内容を取得し、モーダルに表示
      document.getElementById('confirmName').textContent = '名前(Your Name): ' + formData.get('name');
      document.getElementById('confirmEmail').textContent = 'メール(Your Email Address): ' + formData.get('email');
      document.getElementById('confirmSubject').textContent = '件名(Subject): ' + formData.get('subject');
      document.getElementById('confirmMessage').textContent = 'メッセージ(Message): ' + formData.get('message');

      // モーダルを表示
      modal.style.display = 'block';
    };

    // 送信ボタンがクリックされたときの処理
    const handleSubmitClick = (event) => {
      event.preventDefault(); // フォームのデフォルト送信を防ぐ
      const json = JSON.stringify(Object.fromEntries(new FormData(form))); // FormDataをJSON形式に変換

      // ★★★サーバーにデータを送信、ローカルでは8080ポートで、本番環境ではSSL/TSL設定(ホームページとかぶらないよう8443ポートを使用)
      fetch('https://abehiroto.com:8443/submit', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: json
      })
        .then(response => {
          if (!response.ok) {
            if (response.status === 429) {
              alert("Too many requests");
            } else {
              alert("Request error");
            }
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log('Server response:', data);
          alert("Thanks for contact!");
          form.reset(); // 正常に送信された後、フォームをリセット
        })
        .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
        });

      // モーダルを閉じる
      modal.style.display = 'none';
    };

    // クローズボタンがクリックされたときの処理
    const handleCloseClick = () => {
      modal.style.display = 'none';
    };

    // モーダル外の領域をクリックした場合にモーダルを閉じる
    const handleOutsideClick = (event) => {
      if (event.target === modal) {
        modal.style.display = 'none';
      }
    };

    form.addEventListener('submit', handleFormSubmit);
    confirmButton.addEventListener('click', handleConfirmClick);
    submitButton.addEventListener('click', handleSubmitClick);
    closeButton.addEventListener('click', handleCloseClick);
    window.addEventListener('click', handleOutsideClick);

    // クリーンアップ関数
    return () => {
      form.removeEventListener('submit', handleFormSubmit);
      confirmButton.removeEventListener('click', handleConfirmClick);
      submitButton.removeEventListener('click', handleSubmitClick);
      closeButton.removeEventListener('click', handleCloseClick);
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div id="contact-content" className="content">
      <h2>お問い合わせ</h2>
      <form id="contact-form">
        <div className="form-group">
          <label htmlFor="name">お名前：</label>
          <input type="text" id="name" name="name" placeholder="*" className="inquiry-name" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">メールアドレス：</label>
          <input type="email" id="email" name="email" placeholder="* yourmail@example.com" className="inquiry-email" required />
        </div>
        <div className="form-group">
          <label htmlFor="subject">件名：</label>
          <input type="text" id="subject" name="subject" className="inquiry-subject" required />
        </div>
        <div className="form-group">
          <label htmlFor="message">本文：</label>
          <textarea id="message" name="message" placeholder="*" rows="10" className="inquiry-message" required></textarea>
          <p className="form-note">* の項目は必須</p>
        </div>
        <button type="button" id="confirmButton">確認</button>
      </form>
      <div id="confirmationModal" className="modal">
        <div className="modal-content">
          <span className="close-button">&times;</span>
          <h3>お問い合わせ内容確認</h3>
          <p id="confirmName"></p>
          <p id="confirmEmail"></p>
          <p id="confirmSubject"></p>
          <p id="confirmMessage"></p>
          <button type="submit" id="submitButton">送信</button>
        </div>
      </div>
    </div>
  );
}

export default ContactContent;
